import { z } from 'zod';
import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

import { Trigger, Condition, Action, RuleResponse, RuleDetails } from './types/rules-details-types';
import { TriggersUpdatedEvent } from './components/triggers/rules-details-given';
import { ConditionsUpdatedEvent } from './components/conditions/rules-details-when';

import '../../pli/pli-icon';
import '../../pli/pli-button';
import '../../pli/pli-card';
import '../../pli/pli-input';
import '../../pli/pli-textarea';
import '../../pli/pli-progress-stepper';
import './rules-details-simulation';
import './components/triggers/rules-details-given';
import './components/conditions/rules-details-when';

import { Task } from '@lit/task';
import { consume, provide } from '@lit/context';
import { HttpClient, json } from 'aurelia-fetch-client';
import { clientContext } from 'context/client-context';
import { triggerContext } from './context/trigger-context';
import { conditionContext } from './context/condition-context';
import { styles } from 'pli/styles';
import { errorServiceContext } from 'context/error-service-context';
import { ErrorService } from 'error/error-service';
import { confirmServiceContext } from 'context/confirm-service-context';
import { ConfirmService } from 'components/confirmation/confirm-service';
import { Router, routerContext } from 'context/router-context';
import { toastServiceContext } from 'context/toast-service-context';
import { ToastsService } from 'toasts/toasts-service';
import { RuleStatus, ruleStatusKeys } from 'domain/status/rule-status';
import { ruleResponseMapper } from './mappers/rule-response-mapper';
import { AsyncIntervalController } from 'controllers/async-interval-controller';
import { RuleDetailPreviewUpdatedEvent } from './rules-details-simulation';
import { when } from 'lit/directives/when.js';

const nameSchema = z.string().min(1);
const descSchema = z.string().min(1);

const availableRequiredField = ['name', 'description'] as const;
type RequiredField = (typeof availableRequiredField)[number];

@customElement('rules-details')
class RulesDetails extends LitElement {
    static styles = [
        styles.base,
        styles.flex,
        styles.grid,
        styles.form,
        css`
            :host {
                --bottom-offset: var(--size-4-5);
                --button-group-height: var(--bottom-offset);
            }
            .wrapper {
                --padding-bottom: calc(var(--bottom-offset) + var(--size-1));
                padding-bottom: var(--padding-bottom);
            }
            .relative {
                position: relative;
            }
            .button-group {
                --offset: var(--sidebar-width);
                --width: calc(100% - var(--offset));
                position: fixed;
                bottom: 0;
                left: var(--offset);
                width: var(--width);
                height: var(--button-group-height);
                background-color: var(--color-white);
                z-index: var(--z-10);
                border-top: var(--border-default);
                padding: 0 var(--size-1);
            }
            fieldset {
                appearance: none;
                border: 0;
            }

            .condition {
                background-color: var(--color-white);
                padding-left: var(--size-1);
                border-radius: var(--radius-md);
            }

            .back-button {
                margin-left: calc(var(--size-1) * -1);
            }
        `,
    ];

    /* for passing of rule id from aurelia view into this component */
    @property({ type: String, reflect: true })
    ruleid?: string;

    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    public client?: HttpClient;

    @consume({ context: routerContext, subscribe: true })
    @property({ attribute: false })
    public router?: Router;

    @consume({ context: errorServiceContext, subscribe: true })
    @property({ attribute: false })
    public errorService?: ErrorService;

    @consume({ context: confirmServiceContext, subscribe: true })
    @property({ attribute: false })
    public confirmService?: ConfirmService;

    @consume({ context: toastServiceContext, subscribe: true })
    @property({ attribute: false })
    public toastService: ToastsService;

    /* Provided state for child components */
    @provide({ context: triggerContext })
    private _triggers: Trigger[] = [];

    @provide({ context: conditionContext })
    private _conditions: Condition[] = [];

    /* Rule details page state */
    @state()
    _name = null;

    @state()
    _description = null;

    @state()
    _actions: Action[] = [];

    @state()
    _isLoading = false;

    @state()
    _state: RuleStatus = 'Editing';

    @state()
    _ruleDetails: RuleDetails;

    @state()
    _id: string | null = null;

    @state()
    _issues = [];

    _fieldValidationMap = {
        name: () => nameSchema.safeParse(this._name),
        description: () => descSchema.safeParse(this._description),
    };

    _asyncIntervalController = new AsyncIntervalController(this);

    _task = new Task(this, {
        task: async ([id]) => {
            if (!id) return;

            const response = await this.client.get(`rules/${id}`);
            if (!response.ok) {
                return;
            }

            const data = (await response.json()) as RuleResponse;
            if (data.percentage === 100) {
                this.stopPollingForUpdatedRule();
            }
            this.mapResponseToViewData(data);
        },
        args: () => [this._id] as const,
    });

    connectedCallback(): void {
        super.connectedCallback();
        this._id = this.ruleid ?? null;

        if (this.showPreview) {
            this.startPollingForUpdatedRule();
        }
    }

    /* Methods for handling emitted events from child components */
    handleTriggersUpdate = (event: TriggersUpdatedEvent) => {
        this._triggers = [...event.detail.value];
    };

    handleConditionsUpdate = (event: ConditionsUpdatedEvent) => {
        this._conditions = [...event.detail.value];
    };

    handlePreviewUpdate = async (event: RuleDetailPreviewUpdatedEvent) => {
        try {
            const msg = event.detail.value;
            await this.client.put(`rules/${this._id}/from`, JSON.stringify(msg));
            this.startPollingForUpdatedRule();
        } catch (error) {
            this.errorService.showError('Simulation error', error.message);
        }
    };

    /* Methods for handling internal state */
    displaySuccessToast = () => {
        this.toastService.success('Success!', 'Rule saved', 5000);
    };

    validateFieldByName = (field: RequiredField) => {
        const parsed = this._fieldValidationMap[field]();

        if (parsed.error) {
            this.setIssues([...this._issues, field]);
        } else {
            this.setIssues([...this._issues.filter((issue) => issue !== field)]);
        }
    };

    validateRequiredFields = (): { isValid: boolean } => {
        for (const [fieldName, parseFunc] of Object.entries(this._fieldValidationMap)) {
            const result = parseFunc();
            if (result.error) {
                this.setIssues([...this._issues, fieldName]);
            }
        }
        const isValid = this.issues.length === 0;
        return { isValid };
    };

    saveInternal = async () => {
        const { isValid } = this.validateRequiredFields();
        if (!isValid) return;

        let model = {
            name: this._name,
            description: this._description,
            triggers: this._triggers,
            conditions: this._conditions,
            actions: this._actions,
        };

        try {
            if (!this._id) {
                const response = await this.client.post(`rules`, json(model));

                if (!response.ok) {
                    throw new Error('Could not save rule');
                }

                const data = (await response.json()) as RuleResponse;
                this._id = data.ruleId;

                this.router.navigate(`/rules/${data.ruleId}`, {
                    replace: true,
                    trigger: false,
                });
                this.displaySuccessToast();
            } else {
                const response = await this.client.put(`rules/${this._id}`, json(model));
                if (response.ok) {
                    this._task.run();
                }
                this.displaySuccessToast();
            }
        } catch (error) {
            const errorMessages = error.errors ? Object.values(error.errors).map((errorArray) => errorArray[0]) : [];
            this.errorService.showError('Save error', error.message + ' ' + errorMessages.join(', '));
        }
    };

    setIssues(issues: string[]) {
        this._issues = issues;
    }

    setRuleStatus(state: RuleStatus) {
        this._state = state;
    }

    async startPollingForUpdatedRule() {
        this._asyncIntervalController.setAsyncInterval(async () => {
            await this._task.run();
        }, 2_000);
    }

    stopPollingForUpdatedRule() {
        const { clearAsyncInterval, currentIntervalIndex } = this._asyncIntervalController;
        clearAsyncInterval(currentIntervalIndex);
    }

    preview = async () => {
        try {
            this._isLoading = true;
            await this.saveInternal();
            await this.client.put(`rules/${this._id}/preview`);
            this.setRuleStatus('Preview');
            this.startPollingForUpdatedRule();
        } catch (error) {
            this.errorService.showError('Save error', error.message);
        } finally {
            this._isLoading = false;
        }
    };

    review = async () => {
        try {
            this._isLoading = true;
            await this.client.put(`rules/${this._id}/review`);
            this.setRuleStatus('Editing');
            this.stopPollingForUpdatedRule();
        } catch (error) {
            this.errorService.showError('Save error', error.message);
        } finally {
            this._isLoading = false;
        }
    };

    deleteRule = async () => {
        this.confirmService.confirm(
            'Delete Rule?',
            'Are you sure you want to delete the rule. This action cannot be undone.',
            async () => {
                try {
                    await this.client.delete(`rules/${this.ruleid}`);
                    this.router.navigate('rules');
                } catch (error) {
                    this.errorService.showError('Save error', error.message);
                }
            },
        );
    };

    deploy = async () => {
        this.confirmService.confirm(
            'Deploy Rule?',
            'Are you sure you want to deploy the rule. This will create alerts.',
            async () => {
                try {
                    this._isLoading = true;
                    await this.client.put(`rules/${this._id}/deploy`);
                    this.setRuleStatus('Live');
                    this.startPollingForUpdatedRule();
                } catch (error) {
                    this.errorService.showError('Save error', error.message);
                } finally {
                    this._isLoading = false;
                }
            },
        );
    };

    duplicate = async () => {
        this.confirmService.confirm(
            'Clone rule?',
            `Are you sure you want to clone the rule: ${this._name}.`,
            async () => {
                const response: any = await this.client.post(`rules/${this._id}/clone`);
                const id = (await response.json()).ruleId;

                this.router.navigate(`/rules/${id}`);
            },
        );
    };

    pause = async () => {
        try {
            this._isLoading = true;
            await this.client.put(`rules/${this._id}/pause`);
            this.setRuleStatus('Paused');
        } catch (error) {
            this.errorService.showError('Save error', error.message);
        } finally {
            this._isLoading = false;
        }
    };

    resume = async () => {
        try {
            this._isLoading = true;
            await this.client.put(`rules/${this._id}/resume`);
            this.setRuleStatus('Live');
        } catch (error) {
            this.errorService.showError('Save error', error.message);
        } finally {
            this._isLoading = false;
        }
    };

    private mapResponseToViewData(json: RuleResponse) {
        const mappedData = ruleResponseMapper(json);
        const { name, description, triggers, conditions, state, history } = mappedData;
        this._ruleDetails = { ...mappedData };

        this._name = name;
        this._description = description;
        this._triggers = triggers;
        this._conditions = conditions;
        this.setRuleStatus(state);
    }

    get ruleDetails(): RuleDetails {
        return {
            ...this._ruleDetails,
            state: this._state,
        };
    }
    get hasRequiredFields() {
        return [this._name, this._description].every((e) => Boolean(e));
    }
    get isFormEnabled() {
        return this._state === 'Editing' && !this._isLoading;
    }
    get isSimulationEnabled() {
        return this.showPreview && Boolean(this.ruleDetails);
    }
    get showPreview() {
        return this._state !== 'Editing';
    }
    get issues() {
        return this._issues;
    }

    /* Render helper methods */
    _renderEditingButtonGroup = () => {
        return html`
            <pli-button size="lg" .onClick="${this.saveInternal}">
                <pli-icon name="save" slot="icon-left"></pli-icon>
                Save
            </pli-button>
            <pli-button size="lg" ?disabled="${!this.hasRequiredFields}" .onClick="${this.preview}">
                <pli-icon name="send" slot="icon-left"></pli-icon>
                Preview
            </pli-button>
            <pli-button variant="destructive" size="lg" ?disabled="${!this.ruleid}" .onClick="${this.deleteRule}">
                <pli-icon name="trash" slot="icon-left"></pli-icon>
                Delete
            </pli-button>
        `;
    };
    _renderPreviewButtonGroup = () => {
        return html`
            <pli-button size="lg" .onClick="${this.review}">
                <pli-icon name="arrow-counterclockwise" slot="icon-left"></pli-icon>
                Edit
            </pli-button>
            <pli-button size="lg" .onClick="${this.deploy}">
                <pli-icon name="send-check" slot="icon-left"></pli-icon>
                Deploy
            </pli-button>
        `;
    };
    _renderLiveButtonGroup = () => {
        return html` <pli-button size="lg" variant="secondary" .onClick="${this.pause}">
                <pli-icon name="pause-fill" slot="icon-left"></pli-icon>
                Pause
            </pli-button>
            <pli-button size="lg" .onClick="${this.duplicate}">
                <pli-icon name="clouds" slot="icon-left"></pli-icon>
                Duplicate
            </pli-button>`;
    };
    _renderPausedButtonGroup = () => {
        return html` <pli-button size="lg" variant="secondary" .onClick="${this.resume}">
                <pli-icon name="play-fill" slot="icon-left"></pli-icon>
                Resume
            </pli-button>
            <pli-button size="lg" .onClick="${this.duplicate}">
                <pli-icon name="clouds" slot="icon-left"></pli-icon>
                Duplicate
            </pli-button>`;
    };
    _renderButtons = (state: RuleDetails['state']) => {
        const buttonGroups: Record<RuleDetails['state'], TemplateResult> = {
            Live: this._renderLiveButtonGroup(),
            Editing: this._renderEditingButtonGroup(),
            Preview: this._renderPreviewButtonGroup(),
            Paused: this._renderPausedButtonGroup(),
        };
        return buttonGroups[state];
    };
    _renderProgressStepper = (state: RuleDetails['state']) => {
        const filtered: RuleDetails['state'][] = [...ruleStatusKeys.filter((status) => status !== 'Paused')];
        const steps = filtered.length;

        // Paused AND Live should both be treated as a fulfilled last step in stepper
        const lastStepsMerged: RuleStatus[] = ['Paused', 'Live'];
        const currentStep = lastStepsMerged.includes(state) ? filtered.length : filtered.indexOf(state);

        return html`<pli-progress-stepper steps="${steps}" currentStep="${currentStep}"></pli-progress-stepper>`;
    };

    render() {
        const {
            handleTriggersUpdate,
            handleConditionsUpdate,
            handlePreviewUpdate,
            _state,
            ruleDetails,
            _renderButtons,
            _renderProgressStepper,
            isFormEnabled,
            showPreview,
        } = this;

        return html`
            <div class="grid-vertical gap-1 relative wrapper">
                <pli-button class="back-button" variant="text" as="a" size="lg" href="/rules">
                    <pli-icon name="arrow-left-circle" slot="icon-left"></pli-icon>
                    Back to rules
                </pli-button>
                <pli-text as="h1" variant="h1">Rule</pli-text>
                <pli-card>
                    <div class="grid gap-2">
                        <form class="grid-vertical gap-2 col-span-7" .inert="${!isFormEnabled}">
                            <fieldset class="grid-vertical gap-1">
                                <label><strong>Name*</strong></label>
                                <pli-input
                                    value="${this._name}"
                                    .state="${this.issues.includes('name') ? 'invalid' : 'initial'}"
                                    @change="${(event) => {
                                        this._name = event.detail.value;
                                        this.validateFieldByName('name');
                                    }}"
                                ></pli-input>
                                ${this.issues.includes('name')
                                    ? html`<pli-alert-box variant="error">Name is required</pli-alert-box>`
                                    : null}
                            </fieldset>
                            <fieldset class="grid-vertical gap-1">
                                <label><strong>Description *</strong></label>
                                <pli-textarea
                                    .value="${this._description}"
                                    .state="${this.issues.includes('description') ? 'invalid' : 'initial'}"
                                    @change="${(event) => {
                                        this._description = event.detail.value;
                                        this.validateFieldByName('description');
                                    }}"
                                    placeholder="A description for the rule"
                                ></pli-textarea>
                                ${this.issues.includes('description')
                                    ? html`<pli-alert-box variant="error">Description is required</pli-alert-box>`
                                    : null}
                            </fieldset>
                            <rules-details-given @update="${handleTriggersUpdate}"></rules-details-given>
                            <rules-details-when @update="${handleConditionsUpdate}"></rules-details-when>
                        </form>

                        <div class="col-span-5">
                            ${when(
                                this.isSimulationEnabled,
                                () => html`
                                    <rules-details-simulation
                                        @update="${handlePreviewUpdate}"
                                        .rule="${ruleDetails}"
                                    ></rules-details-simulation>
                                `,
                                () => html`
                                    <rules-details-simulation-placeholder></rules-details-simulation-placeholder>
                                `,
                            )}
                        </div>
                        <div class="grid button-group">
                            <div class="col-span-4 col-start-5">${_renderProgressStepper(_state)}</div>
                            <div class="col-span-4 flex items-center justify-end gap-05">${_renderButtons(_state)}</div>
                        </div>
                    </div>
                </pli-card>
            </div>
        `;
    }
}
